import React from 'react';

const Libraries: React.FC = () => {
  return (
    <div>
    <h1>All the awesome open source libraries we use:</h1>
    <u>Thank you all so much! Bye for now would not be possible without you!</u>
    <p>
        @bam.tech/react-native-image-resizer
    </p>
    <p>
        @notifee/react-native
    </p>
    <p>
        @react-native-async-storage/async-storage"
    </p>
    <p>
        @react-native-clipboard/clipboard
    </p>
    <p>
        @react-native-community/cli
    </p>
    <p>
        @react-native-firebase/app
    </p>
    <p>
        @react-native-firebase/messaging
    </p>
    <p>
        @react-native-picker/picker
    </p>
    <p>
        @react-navigation/material-top-tabs
    </p>
    <p>
        @react-navigation/native
    </p>
    <p>
        @react-navigation/native-stack
    </p>
    <p>
        @supabase/supabase-js
    </p>
    <p>
        aes-js
    </p>
    <p>
        base64-arraybuffer
    </p>
    <p>
        d3
    </p>
    <p>
        expo
    </p>
    <p>
        ffmpeg-kit-react-native
    </p>
    <p>
        google-auth-library
    </p>
    <p>
        lottie-react-native
    </p>
    <p>
        react
    </p>
    <p>
        react-native
    </p>
    <p>
        react-native-callkeep
    </p>
    <p>
        react-native-elements
    </p>
    <p>
        react-native-encrypted-storage
    </p>
    <p>
        react-native-fast-image
    </p>
    <p>
        react-native-fast-shadow
    </p>
    <p>
        react-native-fs
    </p>
    <p>
        react-native-gesture-handler
    </p>
    <p>
        react-native-get-random-values
    </p>
    <p>
        react-native-image-colors
    </p>
    <p>
        react-native-image-picker
    </p>
    <p>
        react-native-immersive
    </p>
    <p>
        react-native-incall-manager
    </p>
    <p>
        react-native-linear-gradient
    </p>
    <p>
        react-native-orientation-locker
    </p>
    <p>
        react-native-pager-view
    </p>
    <p>
        react-native-permissions
    </p>
    <p>
        react-native-phone-input
    </p>
    <p>
        react-native-photo-manipulator
    </p>
    <p>
        react-native-qrcode-styled
    </p>
    <p>
        react-native-reanimated
    </p>
    <p>
        react-native-redash
    </p>
    <p>
        react-native-safe-area-context
    </p>
    <p>
        react-native-screens
    </p>
    <p>
        react-native-splash-screen
    </p>
    <p>
        react-native-svg
    </p>
    <p>
        react-native-tab-view
    </p>
    <p>
        react-native-url-polyfill
    </p>
    <p>
        react-native-version
    </p>
    <p>
        react-native-video
    </p>
    <p>
        react-native-vision-camera
    </p>
    <p>
        react-native-webrtc
    </p>
    <p>
        react-native-webview
    </p>
    <p>
        rn-fetch-blob
    </p>
    <p>
        rn-qr-generator
    </p>

    </div>
  );
};

export default Libraries;