import React, { useRef } from 'react';
import { Link, RouterProvider, } from 'react-router-dom';
import title from '../assets/title.png';
import '../App.css';

const HomePage: React.FC = () => {
  const privacyRef = useRef<HTMLDivElement>(null);

  const scrollToPrivacy = () => {
    if (privacyRef.current) {
      privacyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={title} className="Title" alt="Title" />
        <div className="RowItems">
          <a href="https://apps.apple.com/us/app/monogatari/id6502987623">
            <button className="store-button">Download on the App Store</button>
          </a>
          <a href="https://play.google.com/store/apps/details?id=org.monogatari.project">
            <button className="store-button">Get it on Google Play</button>
          </a>
        </div>
      </header>
      <footer className="footer">
        <Link to="/support">
          <button className="store-button">
            support
          </button>
        </Link>
        <Link to="/privacy">
          <button className="store-button">
            privacy policy
          </button>
        </Link>
        <Link to="/libraries">
          <button className="store-button">
            libraries
          </button>
        </Link>
        <Link to="/DeleteAccount">
          <button className="store-button">
            delete account
          </button>
        </Link>
      </footer>
    </div>
  );
}

export default HomePage;
