import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  return (
    <div>
    <h1>Privacy Policy for Bye For Now</h1>
    <p>Last Updated: May 2nd, 2024</p>
    
    <h2>1. Information We Collect</h2>
    <p>
        <strong>1.1. Personal Information:</strong><br />
        When you sign up for Bye For Now, we collect personal information such as your name, email address, phone number, and profile picture. You may choose to provide additional information such as your interests, location, and biography to personalize your experience on Bye For Now.
    </p>
    <p>
        <strong>1.2. Usage Information:</strong><br />
        We collect information about how you use Bye For Now, including your interactions with content, other users, and advertisements. This may include data such as the types of posts you view, the people you follow, and the groups you join.
    </p>
    <p>
        <strong>1.3. Device Information:</strong><br />
        We automatically collect information about the devices you use to access Bye For Now, including device identifiers, IP address, browser type, and operating system. This helps us optimize our services for different devices and provide a better user experience.
    </p>
    <p>
        <strong>1.4. Cookies and Tracking Technologies:</strong><br />
        We use cookies and similar tracking technologies to personalize your experience, analyze trends, and track your movements on Bye For Now. You can manage your cookie preferences through your browser settings.
    </p>

    <h2>2. SMS Notifications</h2>
    <p>
        By using Bye For Now, you may have the option to agree to receive SMS notifications for various purposes, such as account verification, security alerts, and promotional offers. If you choose to receive SMS notifications, we may collect and use your phone number to send you messages. You can opt-out of receiving SMS notifications at any time by adjusting your notification settings within the Bye For Now app or by contacting us.
    </p>
    <p>
        Please note that standard messaging rates may apply depending on your mobile carrier and plan.
    </p>

    <h2>3. How We Use Your Information</h2>
    <p>
        We use the information we collect for the following purposes:
    </p>
    <p>
        <strong>2.1. Providing and Improving Our Services:</strong><br />
        To create and maintain your account. To personalize your experience and tailor content to your interests. To analyze usage patterns and improve our services.
    </p>
    <p>
        <strong>2.2. Communication:</strong><br />
        To communicate with you about your account, updates to our services, and promotional offers. To respond to your inquiries and provide customer support.
    </p>
    <p>
        <strong>2.3. Legal Compliance:</strong><br />
        To comply with applicable laws, regulations, and legal processes. To protect the rights, property, and safety of Bye For Now, our users, and the public.
    </p>

    <h2>4. Information Sharing and Disclosure</h2>
    <p>
        We may share your information with third parties in the following circumstances:
    </p>
    <p>
        <strong>3.1. Service Providers:</strong><br />
        We may engage third-party service providers to perform functions on our behalf, such as hosting, data analysis, and customer service. These service providers have access to your information only to the extent necessary to perform their duties and are obligated to maintain the confidentiality and security of your information.
    </p>
    <p>
        <strong>3.2. Legal Requirements:</strong><br />
        We may disclose your information in response to a subpoena, court order, or other legal process, or to comply with applicable laws and regulations.
    </p>
    <p>
        <strong>3.3. Business Transfers:</strong><br />
        In the event of a merger, acquisition, reorganization, or sale of assets, your information may be transferred as part of the transaction. We will notify you of any such change in ownership or control of your personal information.
    </p>

    <h2>5. Data Security</h2>
    <p>
        We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
    </p>

    <h2>6. Children’s Privacy</h2>
    <p>
        Bye For Now is not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you believe that we have collected information from a child under the age of 13, please contact us immediately.
    </p>

    <h2>7. Changes to this Privacy Policy</h2>
    <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new Privacy Policy on this page.
    </p>

    <h2>8. Contact Us</h2>
    <p>
        If you have any questions or concerns about this Privacy Policy or our practices regarding your information, please contact us at <a href="mailto:vocandid@gmail.com">vocandid@gmail.com</a>.
    </p>
    <p>
        By using Bye For Now, you acknowledge that you have read and understood this Privacy Policy and agree to be bound by its terms and conditions.
    </p>
    </div>
  );
};

export default PrivacyPolicy;